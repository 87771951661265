import * as Sentry from '@sentry/gatsby';
import { getEnvironment, isProductionOrStaging } from './src/js/utils/environment';
import { brand, language } from './src/js/localizations/current-locale';

const sentryDsn = 'https://06448ad0c60e4190a8bfc9f4295db0b4@o451393.ingest.sentry.io/4505516584665088';
const hostName = typeof window !== 'undefined' ? window.location.hostname : '';

const initLogging = (version) => {
  const integrations = [new Sentry.Replay()];

  Sentry.init({
    dsn: sentryDsn,
    integrations,
    environment: getEnvironment(),
    release: version,
    normalizeDepth: 16,
    attachStacktrace: true,
    beforeBreadcrumb: (breadcrumb) => {
      if (isProductionOrStaging() && breadcrumb.category === 'console') {
        return null;
      }
      return breadcrumb;
    },
    beforeSend(event, hint) {
      if (hint.originalException && hint.originalException.stack && (hint.originalException.stack.includes('webpack-internal'))) {
        return null;
      }
      return event;
    },
    ignoreErrors: [
      /has a recent previous application/,
      /TypeError: showDetailCookieInfoBanner\(.*\)/,
      /Error: Window closed before response/,
      /TypeError (xs|Ls|ls)\.sendBulkWithBeacon\(exponea\)/,
      /sendBulkWithBeacon(exponea)/,
      /sendBulkWithBeacon/,
      /Object Not Found Matching Id:/,
      /ChunkLoadError/,
      /exponea/,
      /staging/,
    ],
    tracesSampler: (samplingContext) => {
      if (samplingContext.transactionContext.name === '/partners/bilweb-auto/') {
        return 0;
      }
      return 1;
    },
  });

  Sentry.setTag('hostname', hostName);
  Sentry.setTag('brand', brand);
  Sentry.setTag('language', language);
};

export default initLogging;
