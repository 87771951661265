import React from 'react';
import { Provider } from 'react-redux';

import { ViewportProvider } from './viewport';

import appPackage from '../../../package';

import { FloatingFormProvider } from '../../context/floating-form';
import store from '../store';
import ErrorBoundary from './error-boundary';
import initLogging from '../../../sentry-config';

const appVersion = appPackage.version;

initLogging(appVersion);

// eslint-disable-next-line react/prop-types
const wrapWithProvider = () => ({ element }) => (
  <ViewportProvider>
    <FloatingFormProvider>
      <Provider store={store}>
        <ErrorBoundary>
          {element}
        </ErrorBoundary>
      </Provider>
    </FloatingFormProvider>
  </ViewportProvider>
);
export default wrapWithProvider;
