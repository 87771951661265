import YEARSELECT from '../../../../localizations/config/FI/omalaina/year-select';
import TOTALAMOUNTSELECT from '../../../../localizations/config/FI/omalaina/totalAmount-select';
import textResource from '../../../../utils/text-resources/omalaina/FI/messages';
import submit from '../../../../mappers/FI/application';
import email from '../../../../validators/FI/email';
import validatePhoneNumberFi from '../../../../validators/FI/phoneNumber';

const data = {
  general: {
    mapper: submit,
  },
  backgroundColor: 'var(--white-opacity)',
  links: {
    quickReapply: '/returning-customer/',
    quickReapplyEng: '/en/returning-customer/',
  },
  slides: [
    {
      title: '',
      coApplicantSlide: false,
      fields: [
        {
          component: 'ValidatedForm',
          form: 'Select',
          props: {
            name: 'totalAmount',
            label: textResource.applicationLoanAmountLabel,
            labelOutside: true,
            options: TOTALAMOUNTSELECT,
            lead: true,
            layout: {
              start: 1,
              end: 2,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          props: {
            name: 'repaymentTime',
            label: textResource.repaymentTimeLabel,
            labelOutside: true,
            options: YEARSELECT,
            lead: true,
            layout: {
              start: 2,
              end: 3,
            },
          },
        },
        {
          component: 'MonthlyCost',
          props: {
            label: textResource.monthlyCostApproximateMonthlyCostLabel,
            costLabel: textResource.monthlyCostApproximateCostLabel,
            approximateMonthlyCostDesktop: textResource.monthlyCostApproximateCostPerMonthDesktop,
            approximateMonthlyCostMobile: textResource.monthlyCostApproximateCostPerMonthMobile,
            approximateMonthlyCostYears: textResource.monthlyCostApproximateCostLabelYears,
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'FormTextBlock',
          props: {
            text: textResource.repaymentTimeWarning,
            transparent: true,
            visibleWhen: 'return(formState.values.repaymentTime > 15)',
            margin: false,
            center: false,
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'phoneNumber',
            label: textResource.phoneNumberLabel,
            validate: validatePhoneNumberFi,
            hasValidateFunction: true,
            showErrorMessage: false,
            type: 'tel',
            placeholder: textResource.phoneNumberPlaceholder,
            lead: true,
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'EmailInput',
          sectionName: 'applicant',
          props: {
            name: 'email',
            validate: email,
            hasValidateFunction: true,
            showErrorMessage: false,
            label: textResource.emailLabel,
            placeholder: textResource.emailPlaceholder,
            lead: true,
            layout: {
              start: 3,
              end: 6,
            },
          },
        },
        {
          component: 'AcceptNewsLetter',
          form: 'Checkbox',
          props: {
            name: 'acceptNewsletter',
            text: textResource.acceptNewsletterConsentText,
            lead: true,
            layout: {
              start: 1,
              end: 6,
            },
          },
        },
        {
          component: 'Teleporter',
          sectionName: 'applicant',
          props: {
            destinationPath: '/hae-lainaa/',
            engDestinationPath: '/en/personal-loan/',
            sendAbandonCartEvent: true,
            label: textResource.applyLabel,
            ctaBtnStyle: true,
            disableButton: false,
            lead: true,
            layout: {
              start: 3,
              end: 6,
            },
          },
        },
        {
          component: 'UserAgreementDataPolicy',
          props: {
            userAgreementEng: 'https://www.omalaina.fi/en/user-agreement/',
            userAgreement: 'https://www.omalaina.fi/kayttajaehdot/',
            dataPolicy: 'https://www.omalaina.fi/rekisteriseloste/',
            dataPolicyEng: 'https://www.omalaina.fi/en/privacy-policy/',
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
      ],
    },
  ],
};

export default data;
