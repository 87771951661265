import {
  GrowthBook,
} from '@growthbook/growthbook-react';
import { globalHistory } from '@reach/router';
import queryString from 'query-string';

import { useEffect, useMemo } from 'react';
import { isDevelopmentOrStaging } from './environment';
import { setCookie, getCookie } from './cookies';
import { sendEvent } from './gtm';
import { credentials, brand, region } from '../localizations/current-locale';
import getDeviceType from '../../components/utils/device-type';

const generateGuid = () => {
  if (getCookie('gb_cookie')) {
    return getCookie('gb_cookie');
  }
  const u = Date.now().toString(16) + Math.random().toString(16) + '0'.repeat(16);
  const result = [u.substr(0, 8), u.substr(8, 4), `4000-8${u.substr(13, 3)}`, u.substr(16, 12)].join('-');
  return result;
};
export const useSetupGrowthbook = () => {
  const isBrowser = typeof window !== 'undefined';

  const gbCookieId = isBrowser ? setCookie('gb_cookie', generateGuid(), 7) : '';

  const rawDeviceType = getDeviceType();
  let deviceType;

  if (rawDeviceType === 'ios') {
    deviceType = 'mobile';
  } else if (rawDeviceType === 'ipad' || rawDeviceType === 'tablet') {
    deviceType = '';
  } else {
    deviceType = rawDeviceType;
  }

  const getUTMParameters = () => {
    if (typeof window !== 'undefined') {
      const queryParams = queryString.parse(window.location.search);
      return {
        utm_campaign: queryParams.utm_campaign || '',
        utm_source: queryParams.utm_source || '',
      };
    }
    return {
      utm_campaign: '',
      utm_source: '',
    };
  };

  const { utm_campaign, utm_source } = getUTMParameters();

  const growthbook = useMemo(
    () => new GrowthBook({
      apiHost: 'https://cdn.growthbook.io',
      clientKey: credentials,
      enableDevMode: isDevelopmentOrStaging(),
      hashAttribute: 'id',
      attributes: {
        brand,
        market: region,
        url: typeof window !== 'undefined' ? window.location.href : '',
        id: gbCookieId,
        utm_campaign,
        utm_source,
        deviceType,
        cart_abandonment: 'false',
      },
      trackingCallback: (experiment, result) => {
        sendEvent({
          event: 'growthbook_experiment_viewed',
          growthbook_experimentId: experiment.key,
          growthbook_variant: result.variationId,
          growthbook_cookie: gbCookieId,

        });
      },
    }),
    [gbCookieId, utm_campaign, utm_source, deviceType],
  );

  useEffect(() => {
    if (typeof window !== 'undefined' && credentials !== null && credentials !== undefined && window.self === window.parent) {
      growthbook.loadFeatures({ autoRefresh: true });
      globalHistory.listen(() => {
        growthbook.setURL(window.location.href);
      });
    }
  }, [growthbook, utm_source, utm_campaign]);

  return growthbook;
};

export const updateGrowthBookAttributes = (growthbook, attributes) => {
  growthbook.setAttributes({
    ...growthbook.getAttributes(),
    ...attributes,
  });
};
